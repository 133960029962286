<!-- 顶部框架 -->
<template>
  <div class="header">
    <header class="top">
      <div class="left" @click="backToHome">
        <img src="@/assets/back.png" alt="" />
        <span>返回</span>
      </div>
      <div class="right">
        <router-link :to="item.path" v-for="item in navs" :key="item.path">
          <div
            :class="{ item: true, activeNav: $route.path.includes(item.key) }"
          >
            <img class="icon" :src="item.icon" alt="" />
            <img class="active_icon" :src="item.activeIcon" alt="" />
          </div>
        </router-link>
      </div>
    </header>
    <div class="label">
      <div class="title" :text="showTitle">{{ showTitle }}</div>
      <div class="navs">
        <router-link v-for="item in childNavs" :key="item.path" :to="item.path">
          <div :class="{ nav: true, active: item.path == $route.path }">
            {{ item.name }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navs: [
        {
          name: "走进黑水",
          path: "/move/traffic",
          key: "move",
          icon: require("../assets/nav1.png"),
          activeIcon: require("../assets/nav1_active.png"),
        },
        {
          name: "投资机会",
          path: "/chance",
          key: "chance",
          icon: require("../assets/nav2.png"),
          activeIcon: require("../assets/nav2_active.png"),
        },
        {
          name: "投资考察路线",
          path: "/route",
          key: "route",
          icon: require("../assets/nav3.png"),
          activeIcon: require("../assets/nav3_active.png"),
        },
        {
          name: "投资政策",
          path: "/policy",
          key: "policy",
          icon: require("../assets/nav4.png"),
          activeIcon: require("../assets/nav4_active.png"),
        },
      ],
    };
  },
  props: {
    showTitle: {
      type: String,
      default: "",
    },
    childNavs: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
  mounted() {},
  methods: {
    backToHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang='scss' scoped>
.header {
  min-width: 1100px;
  &::after {
    content: "";
    display: table;
  }
}
.top {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 36px;
    }
    span {
      font-size: 30px;
      font-weight: bold;
      color: #fff;
      margin-left: 21px;
    }
  }
  .right {
    width: 250px;
    height: 50px;
    background-color: #d9e8fc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .item {
      width: 40px;
      height: 40px;
      margin-right: 5px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &:hover {
        cursor: pointer;
        background-color: #183bae;

        .icon {
          display: none;
        }
        .active_icon {
          display: block;
        }
      }

      img {
        width: 21px;
      }
      .active_icon {
        display: none;
      }
    }
    .activeNav {
      background-color: #183bae;
      .icon {
        display: none;
      }
      .active_icon {
        display: block;
      }
    }
  }
}
.label {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    background-image: linear-gradient(to bottom, #daefff, #daefff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 45px;
    font-weight: bold;
    // text-shadow: 0 5px 5px $theme;
  }
  .navs {
    display: flex;

    a {
      text-decoration: none;
      position: relative;
      text-align: center;
      line-height: 50px;
      font-weight: bold;

      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 30%;
        background-color: #fff;
        opacity: 0.4;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:last-child::after {
        width: 0;
      }
    }
    .nav {
      width: 150px;
      height: 50px;
      color: #fff;
      border-radius: 5px;

      &:hover {
        color: $theme;
        background-image: linear-gradient(to bottom, #daefff, #fff);
        box-shadow: 0 3px 5px rgba($color: #1f263b, $alpha: 0.5);
        font-size: 20px;
      }
    }
    .active {
      color: $theme;
      background-image: linear-gradient(to bottom, #daefff, #fff);
      box-shadow: 0 3px 5px rgba($color: #1f263b, $alpha: 0.5);
      font-size: 20px;
    }
  }
}
@media (max-width: 1500px) {
  .top {
    padding: 30px 0;

    .right {
      .item {
        width: 30px;
        height: 30px;

        img {
          width: 18px;
        }
      }
    }
  }
  .label {
    margin-bottom: 20px;

    .title {
      font-size: 35px;
    }
  }
  .label .navs {
    .nav {
      width: 120px;
      height: 40px;
      line-height: 40px;

      &:hover {
        font-size: 18px;
      }
    }
    .active {
      font-size: 18px;
    }
  }
}
@media (max-width: 1400px) {
  .top {
    padding: 20px 0;

    .left {
      img {
        width: 26px;
      }
      span {
        font-size: 26px;
      }
    }
    .right {
      height: 40px;

      .item {
        width: 26px;
        height: 26px;

        img {
          width: 16px;
        }
      }
    }
  }
  .label .title {
    font-size: 30px;
  }
}
</style>
