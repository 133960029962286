<!-- 企业 -->
<template>
  <div class="move-frame">
    <el-backtop :visibility-height="400" @click="backTotop"></el-backtop>
    <div class="body">
      <top showTitle="走进黑水" :childNavs="navs"></top>
      <div class="content" v-show="!detail.id">
        <div class="left">
          <!-- 一级 -->
          <div class="type_item active">
            <div class="show">
              <div class="label">企业</div>
              <el-input
                class="ipt"
                size="mini"
                placeholder="请输入企业名称关键词"
                v-model="queryParams.enterpriseName"
                @keyup.enter.native="startSearch"
              >
                <template #suffix>
                  <i class="search el-icon-search" @click="startSearch"></i>
                </template>
              </el-input>
            </div>
            <!-- 二级 -->
            <div class="sub_list">
              <div class="head_type">
                <div
                  :class="{
                    head_item: true,
                    head_active: queryParams.mainFlag === '',
                  }"
                  @click="changeMain('')"
                >
                  全部
                </div>
                <div
                  :class="{
                    head_item: true,
                    head_active: queryParams.mainFlag == 1,
                  }"
                  @click="changeMain(1)"
                >
                  重点企业
                </div>
              </div>
              <div class="white"></div>
              <div class="table">
                <el-table
                  :data="list"
                  :height="innerHeight"
                  stripe
                  highlight-current-row
                  @current-change="chooseNow"
                >
                  <el-table-column type="index" label="序号"></el-table-column>
                  <el-table-column
                    prop="enterpriseName"
                    label="企业名称"
                  ></el-table-column>
                  <el-table-column
                    prop="industryName"
                    label="所属产业"
                  ></el-table-column>
                </el-table>
              </div>
              <el-pagination
                :hide-on-single-page="total < 10"
                background
                small
                @current-change="pageIdxChange"
                :current-page.sync="queryParams.pageIndex"
                layout="prev, pager, next"
                :total="total"
                :page-size="queryParams.pageSize"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div class="center">
          <div id="container"></div>
          <div class="center_title">
            <i class="arrow el-icon-caret-right"></i>
            <div class="center_label">{{ nowData.enterpriseName }}</div>
          </div>
          <div class="expand" @click="changeExpand">
            <i class="el-icon-d-arrow-right" v-show="isExpand"></i>
            <i class="el-icon-d-arrow-left" v-show="!isExpand"></i>
          </div>
          <div :class="{ center_desc: true, Expand: isExpand }">
            <div class="title">
              <div
                :class="{ type: true, type_active: itemType == 1 }"
                @click="changeItemType(1)"
              >
                概述
              </div>
              <div
                :class="{ type: true, type_active: itemType == 2 }"
                @click="changeItemType(2)"
              >
                企业动态
              </div>
            </div>
            <div class="detail">
              <div class="detail1" v-show="itemType == 1">
                <div class="detail_item">
                  <div class="left">
                    <img src="@/assets/move/qi.png" alt="" class="icon" />
                    <div class="text">企业名称</div>
                  </div>
                  <div class="val">{{ nowData.enterpriseName }}</div>
                </div>
                <div class="detail_item">
                  <div class="left">
                    <img src="@/assets/move/suo.png" alt="" class="icon" />
                    <div class="text">所属产业</div>
                  </div>
                  <div class="val">{{ nowData.industryName }}</div>
                </div>
                <div class="detail_item">
                  <div class="left">
                    <img src="@/assets/move/gui.png" alt="" class="icon" />
                    <div class="text">企业规模</div>
                  </div>
                  <div class="val">{{ nowData.enterpriseSize }}</div>
                </div>
                <div class="detail_item">
                  <div class="left">
                    <img src="@/assets/move/dian.png" alt="" class="icon" />
                    <div class="text">联系方式</div>
                  </div>
                  <div class="val">{{ nowData.linkMobile }}</div>
                </div>
                <div class="detail_item">
                  <div class="left">
                    <img src="@/assets/move/di.png" alt="" class="icon" />
                    <div class="text">联系地址</div>
                  </div>
                  <div class="val">{{ nowData.linkAddress }}</div>
                </div>
                <div class="detail_item">
                  <div class="left">
                    <img src="@/assets/move/jianjie.png" alt="" class="icon" />
                    <div class="text">公司简介</div>
                  </div>
                  <div class="val">
                    {{ nowData.briefIntroduction || "--" }}
                  </div>
                </div>
              </div>
              <!-- 公司动态 -->
              <div class="detail2" v-show="itemType == 2">
                <div
                  class="detail2_item"
                  v-for="item in dynamicList"
                  :key="item.id"
                  @click="getInfo(item)"
                >
                  <div class="detail2_left">{{ item.title }}</div>
                  <div class="detail2_right">
                    {{ handleTime(item) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 动态详情 -->
      <div class="info" v-show="detail.id">
        <el-page-header @back="goBack" content="动态详情"></el-page-header>

        <div class="new">
          <div class="title">{{ detail.title }}</div>
          <div class="time">发布时间：{{ handleTime(detail) }}</div>
          <div class="html" v-html="detail.contentStr"></div>
        </div>
      </div>
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import Bottom from "../../components/bottom.vue";
import top from "../../components/top.vue";
import routes from "../../router/moveWater";

import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  components: { top, Bottom },
  data() {
    return {
      innerHeight: window.innerHeight - 520,
      navs: routes,
      map: null,
      AMap: null,
      queryParams: {
        pageIndex: 1,
        pageSize: 10,
        mainFlag: "",
        enterpriseName: "",
      },
      list: [],
      dynamicList: [],
      total: 0,
      nowData: {},
      detail: {}, // 动态详情
      isExpand: true,
      itemType: 1,
    };
  },
  computed: {
    handleTime() {
      return (item) => {
        let time =
          item.publishTime || item.updateTime || item.createdTime || "";
        return time.split(" ")[0];
      };
    },
  },
  created() {
    const innerWidth = window.innerWidth;
    if (innerWidth < 1400) {
      this.innerHeight = window.innerHeight - 430;
    } else if (innerWidth < 1500) {
      this.innerHeight = window.innerHeight - 470;
    } else {
      this.innerHeight = window.innerHeight - 520;
    }
    if (this.$route.query.name) {
      this.queryParams.enterpriseName = this.$route.query.name;
    }
    this.getList();
  },
  mounted() {},
  methods: {
    async getList() {
      let res = await this.$api.getEnterprise(this.queryParams);
      this.list = res.data.list;
      this.total = res.data.total;
      this.nowData = this.list[0] || {};
      this.getDynamic(this.nowData.id);
      this.map ? this.initCover() : this.initMap();
    },
    // 获取动态
    async getDynamic(id) {
      this.itemType = 1;
      const params = {
        pageIndex: 1,
        pageSize: 100,
        enterpriseId: id,
      };
      let res = await this.$api.getDynamic(params);
      this.dynamicList = res.data.list;
    },
    initMap() {
      AMapLoader.load({
        key: this.$mapKey,
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("container", {
            //设置地图容器id
            zoom: 14, //初始化地图级别
            center: [102.987592, 32.069268], //初始化地图中心点位置
          });

          this.map.addControl(new AMap.Scale());
          this.initCover();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    initCover() {
      let AMap = this.AMap;
      this.map.clearMap();
      let allPathes = [];
      const lnglat = this.nowData.longituAndLatitu;
      allPathes = lnglat ? JSON.parse(lnglat) : [];
      const icon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(25, 25),
        // 图标的取图地址
        image: require("../../assets/move/enterprise_icon.png"),
        // 图标所用图片大小
        imageSize: new AMap.Size(25, 25),
      });
      allPathes.forEach((ele) => {
        let marker = new AMap.Marker({
          position: ele,
          icon,
          offset: new AMap.Pixel(-12, -12),
        });
        marker.setMap(this.map);
      });
      this.map.setFitView();
    },
    // 改变企业类型
    changeMain(type) {
      this.queryParams.mainFlag = type;
      this.getList();
    },
    startSearch() {
      this.getList();
    },
    pageIdxChange(val) {
      this.queryParams.pageIndex = val;
      this.getList();
    },
    chooseNow(val) {
      if (val) {
        this.nowData = val;
        this.detail = {};
        this.initCover();
        this.getDynamic(val.id);
      }
    },
    changeExpand() {
      this.isExpand = !this.isExpand;
    },
    changeItemType(num) {
      this.itemType = num;
    },
    getInfo(item) {
      if (item.newsType == 1) {
        // 链接跳转
        window.open(item.contentStr);
      } else {
        // 内容跳转
        this.detail = item;
      }
    },
    goBack() {
      this.detail = {};
    },
    backTotop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  },
};
</script>

<style lang='scss' scoped>
.content {
  width: 100%;
  background-color: rgb(250 251 253 / 70%);
  position: relative;
  box-shadow: 0 2px 5px rgba($color: #aaa, $alpha: 0.3);
  padding: 34px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .left {
    width: 500px;
    margin-right: 20px;

    .type_item {
      height: 100%;
      background-color: #fff;
      padding: 10px;
      margin-bottom: 10px;
      color: #cfcfcf;
      &:hover {
        color: $theme;
        .show {
          .label {
            color: $theme;
          }
          &::before {
            background-color: $theme;
          }
        }
      }

      .show {
        font-weight: bold;
        padding: 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &::before {
          content: "";
          width: 3px;
          height: 60%;
          border-radius: 10px;
          background-color: #cfcfcf;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .label {
          padding-left: 10px;
          color: #333;
          font-size: 18px;
        }
        .ipt {
          width: 220px;
          border-radius: 18px;

          .search {
            width: 25px;
            font-size: 15px;
            line-height: 28px;
            cursor: pointer;
          }
        }
      }

      .sub_list {
        width: 100%;
        height: calc(100% - 30px);
        box-sizing: border-box;
        margin-top: 7px;
        background-color: #f0f5fa;
        color: #333;
        max-height: 0;
        transition: maxHeight 0.5s;
        overflow: hidden;

        .head_type {
          display: flex;
          justify-content: space-around;

          .head_item {
            padding: 10px 0;
            cursor: pointer;
            position: relative;

            &:hover {
              font-weight: bold;
              color: $theme;
              &::after {
                width: 100%;
              }
            }
            &::after {
              content: "";
              display: block;
              width: 0;
              height: 2px;
              border-radius: 5px;
              background-color: $theme;
              position: absolute;
              left: 0;
              bottom: 0;
              transition: width 0.2s;
            }
          }
          .head_active {
            font-weight: bold;
            color: $theme;
            &::after {
              width: 100%;
            }
          }
        }
        .white {
          width: 100%;
          height: 6px;
          background-color: #fff;
        }
        .table {
          padding: 5px;
        }
      }
    }

    .active {
      color: $theme;

      .sub_list {
        max-height: 1000px;
      }
      .show {
        &::before {
          background-color: $theme;
        }
        .label {
          color: $theme;
        }
        .plus {
          display: none;
        }
        .minus {
          display: block;
        }
      }
    }
  }
  .center {
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    #container {
      width: 100%;
      height: 100%;
    }

    &_title {
      width: 95%;
      height: 40px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      background-color: rgba(246, 251, 255, 0.7);
      border: 1px solid #fff;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrow {
        color: $theme;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      .center_label {
        margin-left: 35px;
        font-weight: bold;
        font-size: 18px;
      }
    }
    .expand {
      position: absolute;
      right: 2.5%;
      top: 70px;
      z-index: 2;
      padding: 7px 5px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 8px;
      background-color: #f0f5fa;
      cursor: pointer;
    }

    &_desc {
      width: 380px;
      height: calc(100% - 80px);
      background-color: #fff;
      border-radius: 5px;
      position: absolute;
      right: 2.5%;
      top: 60px;
      padding: 12px 18px;
      box-sizing: border-box;
      font-size: 14px;
      opacity: 0;
      transform: translateX(100%);
      transition: all 0.5s;

      .title {
        margin-bottom: 12px;
        display: flex;

        .type {
          width: 100px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          border-radius: 5px;
          color: #333;
          margin-right: 10px;
          border: 1px solid #e7e7e7;
          cursor: pointer;

          &:hover {
            color: #fff;
            background-color: $theme;
            border-color: $theme;
          }
        }
        .type_active {
          color: #fff;
          background-color: $theme;
          border-color: $theme;
        }
      }
      .detail {
        max-height: calc(100% - 50px);
        border-top: 1px solid #eee;
        overflow: auto;

        &_item {
          margin-top: 25px;
          display: flex;
          align-items: flex-start;

          .left {
            width: 90px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
          }

          .icon {
            width: 15px;
            margin-right: 15px;
          }
          .text {
            color: #666;
            flex-shrink: 0;
          }
          .val {
            color: $theme;
            word-break: break-all;
          }
        }
      }
      .detail2 {
        padding: 10px;

        &:empty::before {
          content: "暂无动态...";
          font-size: 14px;
          color: #666;
        }

        .detail2_item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          cursor: pointer;

          &:hover {
            color: $theme;
          }

          .detail2_left {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .detail2_right {
            width: 90px;
            flex-shrink: 0;
            text-align: right;
          }
        }
      }
    }
    .Expand {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
.info {
  width: 100%;
  min-height: calc(100vh - 315px);
  padding: 34px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba($color: #aaa, $alpha: 0.3);

  .new {
    text-align: center;

    .title {
      font-size: 25px;
      font-weight: bold;
      margin: 30px 0 10px;
    }
    .time {
      color: #666;
      margin-bottom: 10px;
    }
    .html {
      text-align: left;
    }
  }
}
@media (max-width: 1500px) {
  .content {
    .center_title .center_label {
      font-size: 16px;
    }

    .center_desc {
      width: 300px;
      font-size: 13px;

      .detail_item {
        margin-top: 15px;
      }
    }
    .left {
      width: 400px;

      .type_item {
        font-size: 14px;
      }
    }
  }
}
</style>

